<script>
	import { onMount, tick } from 'svelte';
	import { writable } from 'svelte/store';
	import io from 'socket.io-client';
  
	let qrCodeData;
	let copiaColaData;
	let transactionId = writable("");
	let successMessage = writable("");
	let copyButtonText = 'Copiar Pix Copia-e-Cola';
  
	onMount(() => {
	  const socket = io();
  
	  async function fetchOccurrences(txid) {
		try {
		  const response = await fetch(`/get-occurrences/${txid}`);
		  if (response.ok) {
			const data = await response.json();
			successMessage.set(`${data.occurrences}`);
		  }
		} catch (error) {
		  console.error('Error fetching occurrences:', error);
		}
	  }
  
	  socket.on('connect', () => {
		socket.emit('requestQRCode');
		const storedTxid = localStorage.getItem('txid');
		if (storedTxid) {
		  fetchOccurrences(storedTxid);
		}
	  });
  
	  socket.on('qrCodeData', (data) => {
		console.log(data)
		qrCodeData = data.qrCode;
		copiaColaData = data.copiaCola;
		transactionId.set(data.txid);
		localStorage.setItem('txid', data.txid);
	  });
  
	  socket.on('checkTxid', (incomingTxid) => {
		const storedTxid = localStorage.getItem('txid');
		if (incomingTxid === storedTxid) {
		  fetchOccurrences(storedTxid);
		}
	  });
	});
  
	async function copyToClipboard() {
		try {
			// Select the text inside the copia-cola element
			const copiaColaElement = document.querySelector('.copia-cola');
			selectText(copiaColaElement);

			// Copy the text to clipboard
			await navigator.clipboard.writeText(copiaColaData);
			copyButtonText = 'Copiado! Após pagar, retorne a este site e você automaticamente verá quantos pix de R$1 foram feitos';
		} catch (err) {
			console.error('Failed to copy: ', err);
		}
	}
  
	function selectText(element) {
	  if (window.getSelection && document.createRange) {
		const range = document.createRange();
		range.selectNodeContents(element);
		const sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	  }
	}
  
	function handleContainerClick(event) {
	  selectText(event.currentTarget);
	}
  
	function handleKeyDown(event) {
	  if (event.target.classList.contains('copia-cola') && (event.ctrlKey || event.metaKey) && event.key === 'a') {
		event.stopPropagation();
	  } else if (event.key === 'a') {
		event.preventDefault();
	  }
	}

	function generateRandomNumber() {
		const min = 1;
		const max = 99999;
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	function updateDisplay() {
		document.querySelector('.number-display.blur').innerText = generateRandomNumber();
	}

	setInterval(updateDisplay, 100);

  </script>
  
  <main>
	<h1>Quantos Pix de R$1 foram feitos? 🤔</h1>
	
	{#if $successMessage} 
	  <div class="number-display">{$successMessage}</div>
	{:else}
	  <div id="question-mark">?</div>
	  <div class="number-display blur">1</div>
	  <h3 style="margin-top: 1em">faça um Pix de R$1 para descobrir</h3>
	  {#if copiaColaData}
		<div class="copia-cola-container" on:click={handleContainerClick} on:keydown={handleKeyDown}>
		  <div class="copia-cola">
			{copiaColaData}
		  </div>
		</div>
		<button on:click={copyToClipboard}>{copyButtonText}</button>
		<br><br><br>
		ou leia o QR code abaixo:
	  {/if}
	  {#if qrCodeData}
		<div>
		  <img src={`${qrCodeData}`} alt="QR Code" />
		</div>
	  {:else}
		<p>Gerando chave pix...</p>
	  {/if}
	{/if}
  </main>
  
<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 320px;
		margin: 0 auto;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.copia-cola-container {
		margin-top: 20px;
		position: relative;
	}

	.copia-cola {
		height: 20px; /* Adjust height as needed */
		overflow-y: scroll;
		border: 1px solid #ccc;
		padding: 10px;
		text-align: left;
		cursor: pointer;
	}

	button {
		margin-top: 10px;
		padding: 5px 10px;
		cursor: pointer;
		width: 100%;
	}
	.number-display{
		font-size: 70px;
		font-weight: bold;
	}
	.number-display.blur{
		filter: blur(9px);
		pointer-events: none;
	}
	#question-mark{
		position: absolute;
		font-size: 90px;
		font-weight: 900;
		color: #ffca29;
		left: 50%;
		margin-left: -25px;
		margin-top: -14px;
		z-index: 999;
	}
</style>
  